import React from 'react';

import Wrapper from './Wrapper';
import Row from 'components/Row';

const linksStyles = {fontSize: 13, padding: '5px 10px', color: 'white', cursor: 'pointer', marginRight: 5}

function Footer() {
  return (
    <Wrapper style={{ marginTop: -10, }}>
        <Row style={{ marginTop: 0 ,minWidth:"300px"}}>
          <Row>
            <p style={{ margin: 0, color: 'white', fontSize: 13 }}>{new Date().getFullYear()} © Yassir</p>
          </Row>
          <Row>

          </Row>
          <Row style={{ textAlign: 'right', display: 'block' }}>
            <div style={{ display: 'block', textAlign: 'right' }}>
              {/* <a style={linksStyles} href={"#about"}>About</a>
              <a style={linksStyles} href={"#support"}>Support</a>
              <a style={linksStyles} href={"#contact"}>Contact</a> */}
            </div>
          </Row>
      </Row>
    </Wrapper>
  );
}

export default Footer;
